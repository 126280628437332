<template>
  <nav id="top-menu">
    <!-- <button class="toggle-btn" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMainMenu" aria-controls="sidebarMainMenu" aria-expanded="true" aria-label="Toggle navigation">
      toggle
    </button> -->
    <button class="btn toggle-btn back-btn" v-if="backBtn" v-on:click="$router.go(-1)">
      <i class="fas fa-chevron-left"></i>
    </button>
    <header>
      <!-- <div class="logo"></div> -->
      <b-button v-b-toggle.modal-menu variant="light" class="modal-menu-caller">
        <span class="material-icons-outlined">menu</span>
      </b-button>

      <template v-if="!whitelabelReady">
        <div class="placeholder-logo"></div>
      </template>
      <template v-else-if="whitelabelLogo">
        <img :src="whitelabelLogo" class="whitelabel-logo" />
      </template>
      <template v-else>
        <img class="whitelabel-logo logo-light-mode" src="@/assets/logo/logo-light-mono-tagline.svg" alt="Yup Chat" />
        <img class="whitelabel-logo logo-dark-mode" src="@/assets/logo/logo-dark-tagline.svg" alt="Yup Chat" />
      </template>

      <div class="top_menu_items">
        <!-- <account-select /> -->
        <div class="d-flex">
          <!-- <div class="col-6">
          <b-dropdown right class="notifications top-dropdown">
            <template #button-content>
              <i class="fas fa-bell"></i>
            </template>
            <b-dropdown-form>
              <h4>{{$tc('generic-str.notification', 2)}}</h4>
            </b-dropdown-form>
          </b-dropdown>
        </div> -->
          <div class="px-1 align-self-center">
            <div class="btn-toolbar-header" style="margin-right: 20px" @click="hasNotification = false">
              <router-link to="/notifications" v-tooltip.bottom="'Notificações'" class="notification-link relative">
                <div class="omni-pushs bg-danger" v-if="hasNotification"></div>
                <span class="material-symbols-outlined notification-icon">notifications</span>
              </router-link>
            </div>
          </div>
          <div class="px-1 align-self-center">
            <!-- <ul class="navbar-nav nav-right">
              <li
                v-if="$store.state.account.type === 'trial'"
                class="nav-item d-none d-sm-block">
                <router-link to="/billing/upgrade" class="btn-toolbar-header">
                  <i class="fa fa-crown"></i> Nossos planos
                </router-link>
              </li>
            </ul> -->
            <div class="btn-toolbar-header">
              <account-select />
            </div>
          </div>
          <div class="text-right pl-1">
            <b-dropdown right class="profile top-dropdown">
              <template #button-content>
                <span class="dev-tag" :class="{ hide: isProduction }">dev</span>
                <!-- Condition for not found profile image here -->
                <img v-if="profilePicture" :src="profilePicture"
                  referrerpolicy="no-referrer" class="profile-pic no-profile-pic" />
                <span v-else id="no-picture" class="profile-pic no-profile-pic">{{ name | initials }}</span>
                <!-- <i
                :class="`status-online status ${$live.status}`"
                v-if="account.is_agent"></i> -->
                <!-- <span class="badge live-break" v-if="account.is_agent">
                {{$live.statusTime | roundTime}}
              </span> -->
                <div v-if="account.is_agent" class="status-online status" :class="{
                  'bg-success': $live.status === 'AVAILABLE',
                  'bg-warning': $live.status === 'UNAVAILABLE',
                  'bg-danger': $live.status === 'OFFLINE',
                  'bg-info': $live.status === 'BREAK',
                }"></div>
              </template>
              <li style="position: relative">
                <router-link to="/accounts" class="dropdown-item"
                  style="width: 100%; overflow: hidden; padding: 0;padding-left: 10px;">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col" style="padding: 15px 0;display: flex;justify-content: center;">
                        <span class="material-icons-outlined"> person_outline </span>
                      </div>
                      <div id="accNameWrapper" class="col-10" style="padding: 15px 0;overflow: hidden; display: flex;">
                        <span id="accName" style="margin-right: auto;padding-right: 5px;">{{ name }}</span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <span class="badge badge-warning badge-select ml-2" v-if="account.type == 'trial'"
                  style="position: absolute;bottom: -9px;right: 0;transform: scale(.9);z-index: 5;">
                  {{ $t('acc-settings.trial') }}
                </span>
              </li>
              <template v-if="account.is_agent">
                <b-dropdown-divider></b-dropdown-divider>
                <li class="dropdown-item">Alternar status</li>
                <b-dropdown-item @click="$live.setStatus('AVAILABLE')" :active="$live.status === 'AVAILABLE'">
                  <!-- <i class="fas fa-check-circle text-success"></i> -->
                  <span class="live-status bg-success"></span>
                  Disponível
                </b-dropdown-item>
                <b-dropdown-item @click="$live.setStatus('UNAVAILABLE')" :active="$live.status === 'UNAVAILABLE'">
                  <span class="live-status bg-warning"></span>
                  Indisponível
                </b-dropdown-item>
                <!-- <b-dropdown-item
                @click="$live.setStatus('SHORT_BREAK');"
                :active="$live.status === 'SHORT_BREAK'">
                <span class="live-status bg-info"></span>
                Pausa curta
              </b-dropdown-item> -->
                <b-dropdown-item @click="$live.setStatus('BREAK')" :active="$live.status === 'BREAK'">
                  <!-- <i class="fas fa-pause"></i> -->
                  <span class="live-status bg-info"></span>
                  Pausa
                </b-dropdown-item>
                <!-- <b-dropdown-item
                @click="$live.setStatus('LONG_BREAK');"
                :active="$live.status === 'LONG_BREAK'">
                <span class="live-status bg-info"></span>
                Pausa longa
              </b-dropdown-item> -->
                <b-dropdown-item @click="$live.setStatus('OFFLINE')" :active="$live.status === 'OFFLINE'">
                  <!-- <i class="fas fa-minus-circle text-danger"></i> -->
                  <span class="live-status bg-danger"></span>
                  Offline
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
              </template>
              <b-dropdown-divider></b-dropdown-divider>
              <li>
                <div class="dropdown-item">
                  <span>{{ $t('home.basic-info.limit') }}:</span>
                  <div class="w-100"></div>
                  <router-link type="button" to="/billing" :class="{
                    btn: true,
                    'stretched-link': true,
                    'btn-danger': account.balance <= 0,
                    'btn-warning':
                      account.balance > 0 && account.balance < 10,
                  }">
                    {{ account.balance | currency }}
                  </router-link>
                </div>
              </li>
              <b-dropdown-divider></b-dropdown-divider>
              <li role="presation">
                <router-link class="dropdown-item" to="/user/settings">
                  <span class="badge live-break" v-if="account.is_agent">
                    {{ $live.statusTime | roundTime }}
                  </span>
                  <!-- <i class="fas fa-user fa-fw"></i> -->
                  <!-- <div class="trimmed-text">{{ name }}</div> -->
                  <span class="material-icons-outlined mr-3"> settings </span>
                  <div class="trimmed-text">
                    {{ $t('generic-str.menu.config') }}
                  </div>
                </router-link>
                <router-link to="/billing" :class="{
                  badge: true,
                  'badge-balance': true,
                  'bg-primary': true,
                  'bg-danger': account.balance <= 0,
                  'bg-warning': account.balance > 0 && account.balance < 10,
                }">
                  {{ account.balance | currency }}
                </router-link>
              </li>
              <li>
                <b-dropdown-item v-on:click="logout">
                  <span class="material-icons-outlined mr-3"> logout </span>
                  <div>Logout</div>
                </b-dropdown-item>
              </li>
              <!-- <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>
              <i class="fas fa-circle icon-online"></i> Online
            </b-dropdown-item>
            <b-dropdown-item>
              <i class="fas fa-circle icon-away"></i> Ausente
            </b-dropdown-item>
            <b-dropdown-item>
              <i class="fas fa-circle icon-invisible"></i> Invisivel
            </b-dropdown-item> -->
              <!-- <b-dropdown-divider></b-dropdown-divider> -->
              <!-- <b-dropdown-item>{{$live.status}}</b-dropdown-item> -->
            </b-dropdown>
          </div>
        </div>
        <!-- <Darkmode /> -->
      </div>
    </header>
  </nav>
</template>

<script>
import AccountSelect from '@/components/rebranding/menu/AccountSelect.vue';
import Vue from 'vue';
// import Darkmode from '@/components/ToggleDark.vue';
import $ from 'jquery';

const audio = new Audio('/sounds/alert.mp3');

export default {
  name: 'Toolbar',
  components: {
    AccountSelect,
    // Darkmode,
  },
  props: {
    backBtn: Boolean,
  },
  filters: {
    initials(name) {
      let [f, l] = name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
    roundTime: (value) => {
      if (!value) return '--:--';

      const hours = Math.floor(value / 60 / 60);
      const mins = Math.floor((value / 60) % 60);
      const secs = Math.floor(value % 60);
      return `${hours}:${mins}:${secs}`
        .replace(/\b(\d)\b/g, '0$1')
        .replace(/^00:/, '');
    },
  },
  computed: {
    account() {
      return this.$store?.state?.account;
    },
    name() {
      return this.$store?.state?.auth?.user?.name;
    },
    email() {
      return this.$store?.state?.auth?.user?.email;
    },
    menu() {
      return this.$route.path.split('/')[1];
    },
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },
    whitelabelReady() {
      return !!(this.$store?.state?.admin?.whitelabel?.id);
    },
    whitelabelLogo() {
      return this.$store?.state?.admin?.whitelabel?.logo ?? null;
    },
    profilePicture() {
      return this.$store.state.auth.user.profile_picture ?? null;
    },
  },
  // data() {
  //   return {
  //     name: this.$store.state.auth.user.name,
  //     email: this.$store.state.auth.user.email,
  //     menu: this.$route.path.split('/')[1],
  //   };
  // },
  data() {
    return {
      hasNotification: false,
    };
  },
  created() {
    this.$root.$on('notify:new', (notification) => {
      let type = notification.type.toLowerCase();
      switch (type) {
        case 'alert':
          type = 'danger';
          break;
        case 'text':
          type = 'info';
          break;
        default:
          break;
      }
      this.$toast.show({
        title: notification.title,
        content: notification.content,
        type,
      });
      audio.play();
      this.hasNotification = true;
    });
    this.$notification.setup(this);
  },
  beforeDestroy() {
    this.$root.$off('notify:new', this.fn);
  },
  mounted() {
    // this.checkLive();
    function checkOverflow(el) {
      // need to be inline
      // const curOverflow = el.style.overflow; if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden'; const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight; el.style.overflow = curOverflow;
      const curOverflow = el.style.overflow;

      if (!curOverflow || curOverflow === 'visible') {
        el.style.overflow = 'hidden';
      }

      const isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

      el.style.overflow = curOverflow;

      return isOverflowing;
    }
    setTimeout(() => {
      Vue.prototype.$filters = Vue.options.filters;
      const accName = this.name;
      // const profileLetter = accName.charAt(0);
      // Random bg color
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      // const randomColor = '000000';
      // const randomColor = '7906d9';
      // const randomColor = 'FFFFFF';

      console.log('Teste color: ', this.isLighter(randomColor, 127.5));
      const isLight = this.isLighter(randomColor, 127.5);
      console.log('Valor: ', isLight);
      // this.lighterColor = this.isLighter(randomColor, 127.5);
      if (isLight === false) {
        $('.profile-pic').addClass('lighter');
        $('body').append(`
        <style>
          .profile-pic.profile-random-color {
            color: #FFFFFF;
          }
        </style>
      `);
      }

      $('#top-menu .profile > button').attr('style', `background-color: ${this.$filters.strToColor(this.name)} !important;`);
      $('body').append(`
        <style>
          .profile-random-color {
            background-color: #${randomColor};
          }
        </style>
      `);

      // document.body.style.backgroundColor = "#" + randomColor;
      // console.log('Acc Name: ', accName, 'First letter: ', profileLetter);
      // $('#no-picture').html(profileLetter);

      $('.profile .btn.dropdown-toggle').on('click', () => {
        const accName = document.getElementById('accName');
        setTimeout(() => {
          if (checkOverflow(accName) === true) {
            console.log('It is overflowing');
            let width = document.getElementById('accNameWrapper');
            let width2 = document.getElementById('accName');
            let result = 0;
            const letterCount = $('#accName').text().length;
            console.log('Letter Count: ', letterCount);
            width = width.offsetWidth;
            width2 = width2.offsetWidth;
            result = width2 - width;
            $('body').append(`
            <style>
              /* #accNameWrapper {
                justify-content: end;
              } */
              .slideAnim {
                animation-duration: ${letterCount}s !important;
              }
              @keyframes slideAnimation {
                    0%   {right:0px;}
                    25%  {right:${result}px}
                    50%  {right:0px;}
                    75%  {right:${result}px}
                    100% {right:0px;}
                }
            </style>
            `);
            $('#accName').addClass('slideAnim');
          }
        }, 500);
      });
    }, 1000);
  },
  methods: {
    checkLive() {
      // this.hasNotification = this.$live.hasNotification;

      // // console.log('this.hasNotification', this.hasNotification);
      // setTimeout(() => this.checkLive(), 1000);

      // this.isLiveOnline = this.$live.online;

      // console.log('Sidebar.vue:checkLive');

      this.$live.setup(this);

      // this.$live.on('$live:ready', () => {
      //   console.log(this.isLiveOnline);
      // });

      // this.$live.on('online', () => {
      //   console.log('Toobar.vue:online');
      //   this.isLiveOnline = true;
      // });

      // this.$live.on('offline', () => {
      //   console.log('Toobar.vue:offline');
      //   this.isLiveOnline = false;
      // });
    },
    async logout() {
      console.log('LOGOUT', this.$live.ready);

      await this.$live.disconnect();

      this.$store.dispatch('auth/logout').then(async () => {
        this.$router.push('/login');
      });
    },
    getInitials(str) {
      const splitNames = str.trim().split(' ');
      if (splitNames.length > 1) {
        if (splitNames[1].length > 3) {
          return `${splitNames[0].charAt(0).toUpperCase()}${splitNames[1]
            .charAt(0)
            .toUpperCase()}`;
        }

        return `${splitNames[0].charAt(0).toUpperCase()}${splitNames[2]
          .charAt(0)
          .toUpperCase()}`;
      }
      return splitNames[0].charAt(0).toUpperCase();
    },
    isLighter(input, maxHsp) {
      /* eslint no-bitwise: ["error", { "allow": [">>", "&"] }] */
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      console.log('What arrive color: ', input, maxHsp);
      if (input.indexOf('#') === 0) input = input.slice(1);

      if (input.length < 5) input = input.replace(/./g, '$&$&');

      const color = +`0x${input}`;

      const r = color >> 16;
      const g = (color >> 8) & 255;
      const b = color & 255;

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      return (
        Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) > maxHsp
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/_variables';

.modal-menu-caller {
  display: none;
  padding: 0 !important;
  font-size: 1.6rem;
  height: 3.2rem;
  width: 7.2rem;
  background: #fc0;

  @media (max-width: 992px) {
    display: block;
  }
}

.trimmed-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  width: 200px;
  vertical-align: middle;
}

.initials {
  background-color: #d6dadd;
  color: #4d5a68;
  font-weight: 600;
  line-height: 2.5;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

#top-menu {
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  min-width: 320px;
  border-bottom: 1px solid #d6dadd;
  background: var(--background-2);
  height: 62px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

  .btn-balance {
    border: none;
    border-radius: 1em;
    // padding: 0.2em 1em;
    // margin: 1em 0.5em 0 0 !important;
    // float: right;
    font-size: 0.8rem;
    // height: auto !important;
    display: none;
    width: fit-content;

    @media (min-width: 660px) {
      display: flex;
      align-items: center;
    }
  }

  .badge-balance {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    display: none;

    @media (max-width: 659px) {
      display: block;
      background: #fc0;
    }
  }
}

.darkmode #top-menu {
  border-bottom: 1px solid var(--background-3);
}

#top-menu header {
  padding: 4px 0;
  display: flex;
  align-items: center;
  width: 100%;
}

#top-menu .toggle-btn {
  float: left;
  border: none !important;
  background: transparent center center no-repeat !important;
  color: transparent !important;
  border: none !important;
  border-right: 1px solid #d6dadd !important;
  width: 62px !important;
  height: 62px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.darkmode #top-menu .toggle-btn {
  border-right: 1px solid var(--background-3) !important;
}

/* #top-menu .toggle-btn:not(.back-btn) {
  background-image: url(../../assets/btn-menu.svg) !important;
} */

#top-menu .logo {
  background: url(../../../assets/logo.svg) no-repeat;
  background-size: contain;
  width: 168px;
  height: 30px;
  float: left;
  margin-top: 0.7em;
  margin-left: 1.5em;
  position: relative;
}

#top-menu>header {

  .placeholder-logo,
  .whitelabel-logo {
    width: 120px;
    height: 30px;
    float: left;
    /* margin-top: 0.7em; */
    margin-left: 1.5em;
    position: relative;
  }
}

.dev-tag {
  position: absolute;
  top: -4px;
  right: -4px;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.1em 0.5em;
  border-radius: 0.3em;
  color: #fff;
  text-transform: uppercase;
  font-size: 7pt;
}

/* #top-menu .row {
  // margin: 0;
  // margin-top: 0.35em;
  // margin-right: 0.25em;
  // max-width: 92px;
  // float: right;

  & > div {
    padding: 0;
    width: 46px;
  }
} */

#top-menu .dropdown::v-deep {
  border-radius: 100%;
  position: relative !important;
  height: 2.5em !important;
  width: 2.5em !important;
  color: #0f0f0f !important;
  border: none !important;
}

#top-menu .dropdown::v-deep>button {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}

/* #top-menu::v-deep .dropdown.show > button {
  color: #752de6 !important;
} */

#top-menu::v-deep .dropdown>button:hover {
  filter: saturate(0.7);
}

#top-menu::v-deep .dropdown>button i {
  margin: 0 !important;
  color: inherit !important;
}

#top-menu::v-deep .dropdown>button::after {
  display: none;
}

#top-menu::v-deep .profile>button {
  background: #9ca7b0 !important;
  color: #fff !important;
  overflow: auto;
}

#top-menu::v-deep {
  .live-status {
    border-radius: 12px;
    vertical-align: -2px;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }

  .live-break {
    min-width: 42px;
    text-align: center;
    font-size: 8pt;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    color: #fff;
    padding: 0 4px;
    position: absolute;
    // bottom: 0;
    // right: 0;
    // top: auto;
    top: -5px;
    right: 3px;
  }
}

#top-menu::v-deep .profile>button .status {
  border: 0.2em solid #fff;
  background: #ff0000;
  width: 1em;
  height: 1em;
  position: absolute;
  bottom: -0.3em;
  left: -0.3em;
  content: '';
  border-radius: 100%;

  &.online {
    background: #00cc00;
  }
}

#top-menu::v-deep .dropdown-menu {
  /* top: auto !important;
  left: auto !important; */
  transform: none !important;
}

#top-menu::v-deep .profile .fa-circle {
  font-size: 9pt;
}

#top-menu::v-deep .profile .dropdown-item>i {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

#top-menu::v-deep .notifications>button {
  background: transparent;
  color: #d6dadd;
  border: none;
}

/*#top-menu .notifications button:hover {
  color: #752de6 !important;
}*/

#top-menu::v-deep .notifications form {
  padding: 0;
}

#top-menu::v-deep .notifications form h4 {
  font-weight: bold;
  font-size: 110%;
  padding: 0.9em;
  background: #fff;
  margin: 0;
  border-bottom: 1px solid #d6dadd;
}

#top-menu::v-deep .notifications form aside {
  border: none !important;
  border-bottom: 1px solid #fcfcfc;
  max-height: 320px;
  overflow: auto;
}

.top_menu_items {
  margin-left: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 1rem;

  &::v-deep {
    .dropdown .dropdown-menu {
      top: 40px !important;
    }
  }
}

@media (max-width: 420px) {
  #top-menu .logo {
    margin-top: 0.85em;
    width: 110px;
  }

  #top-menu .notification-content {
    position: fixed !important;
    left: 1% !important;
    transform: translate(0, 50px) !important;
    height: auto !important;
    width: 98% !important;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3) !important;
  }

  #top-menu .notification-content aside {
    max-height: 70vh !important;
  }
}

#no-picture {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 18px;
}

::v-deep .lighter,
::v-deep .profile-pic.profile-random-color {
  color: #FFF !important;
}

.nav-right {
  margin-right: 0px;
}

.text-right {
  text-align: right !important;
}

.btn-toolbar-header {
  max-width: 200px;
  display: inline-block;
  text-align: center;
  color: #F7F7F7;
  font-size: 16px;
  margin-top: 3px;
}

.btn-toolbar-header i {
  color: white;
  margin-right: 5px;
}

.notification-icon {
  color: #fff !important;
  font-size: 20px;
}

.notification-link {
  background: var(--clr-yup-purple) !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  display: block;
}

.notification-link:hover {
  background: #9c60f9 !important;
}

.omni-pushs {
  position: absolute;
  right: 22px;
  top: 0px;
  border-radius: 40px;
  width: 12px;
  height: 12px;
  background: #f00;
  border: 2px solid #fff;
}

.bg-danger {
  background-color: #ff5c75 !important;
}
</style>
